import { hi } from 'date-fns/locale';
import React from 'react';

interface ButtonProps {
  label: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  active?: boolean;
  style?: React.CSSProperties;
  size?: 'sm' | 'md' | 'lg';
  variant?: 'primary' | 'outline-primary' | 'success' | 'warning' | 'error' | "blue";
  maxLines?: number;
  hidden?: boolean;
  className?: string;
}

export const MyButton: React.FC<ButtonProps> = ({
  label,
  onClick,
  disabled = false,
  active = false,
  style = {},
  size = 'md',
  variant = 'primary',
  maxLines = 1,
  className,
  hidden = false,
}) => {
  // Only apply default styles if no className is provided
  const shouldApplyDefaultStyles = !className;

  // Base styles that apply to all buttons
  const baseStyle: React.CSSProperties = shouldApplyDefaultStyles ? {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.375rem',
    borderRadius: '0.25rem',
    fontWeight: 500,
    transition: 'all 150ms ease',
    cursor: disabled ? 'not-allowed' : 'pointer',
    opacity: disabled ? 0.5 : 1,
    outline: 'none',
  } : {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.375rem',
    opacity: disabled ? 0.5 : 1,
    cursor: disabled ? 'not-allowed' : 'pointer',
  };

  // Size variations
  const sizeStyles: Record<string, React.CSSProperties> = {
    sm: { padding: '0.25rem 0.75rem', fontSize: '0.75rem' },
    md: { padding: '0.375rem 1rem', fontSize: '0.875rem' },
    lg: { padding: '0.5rem 1.5rem', fontSize: '1rem' },
  };

  // Color palette
  const colors = {
    primary: {
      base: '#1e3a8a',
      hover: '#1e60af',
      text: 'white',
    },
    success: {
      base: '#059669',
      hover: '#047857',
      text: 'white',
    },
    warning: {
      base: '#d97706',
      hover: '#b45309',
      text: 'white',
    },
    error: {
      base: '#dc2626',
      hover: '#b91c1c',
      text: 'white',
    },
    blue: {
      base: '#007bff',
      hover: '#026cdd',
      text: 'white',
    },
  };

  // Get the current color set based on variant
  const getColorSet = (variant: string) => {
    if (variant === 'outline-primary') return colors.primary;
    return colors[variant as keyof typeof colors] || colors.primary;
  };

  const currentColors = getColorSet(variant);

  // Variant base styles
  const getVariantStyle = (variant: string, isHovered: boolean): React.CSSProperties => {
    const colorSet = getColorSet(variant);

    if (variant === 'outline-primary') {
      return {
        backgroundColor: isHovered ? colorSet.base : 'white',
        color: isHovered ? colorSet.text : colorSet.base,
        border: `1px solid ${colorSet.base}`,
      };
    }

    return {
      backgroundColor: isHovered ? colorSet.hover : colorSet.base,
      color: colorSet.text,
      border: 'none',
    };
  };

  // Active state styles
  const activeStyle: React.CSSProperties = active && shouldApplyDefaultStyles
    ? {
      backgroundColor: currentColors.hover,
      color: currentColors.text,
    }
    : {};

  // Focus state styles
  const [isFocused, setIsFocused] = React.useState(false);
  const focusStyle: React.CSSProperties = isFocused && shouldApplyDefaultStyles
    ? {
      boxShadow: `0 0 0 2px ${currentColors.base}40`,
    }
    : {};

  // Hover state
  const [isHovered, setIsHovered] = React.useState(false);

  // Label styles for text overflow
  const labelStyle: React.CSSProperties = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: maxLines,
    lineClamp: maxLines,
  };

  const computedStyles: React.CSSProperties = {
    ...baseStyle,
    ...(shouldApplyDefaultStyles ? {
      ...sizeStyles[size],
      ...getVariantStyle(variant, isHovered && !disabled),
      ...activeStyle,
      ...focusStyle,
    } : {}),
    ...style,
  };

  return (
    <button
      hidden={hidden}
      onClick={onClick}
      disabled={disabled}
      className={className}
      style={computedStyles}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span style={labelStyle}>{label}</span>
    </button>
  );
};

export default MyButton;