import { Clear } from '@material-ui/icons';
import React from 'react';

interface DateInputProps {
  value: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  startDate?: string | null;
  endDate?: string | null;
  isClearable?: boolean;
}

export const DateInput: React.FC<DateInputProps> = ({
  value,
  onChange,
  placeholder = 'Select date',
  disabled = false,
  className = '',
  startDate = null,
  endDate = null,
  isClearable = false,
}) => {
  const handleClear = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (onChange) {
      onChange('');
    }
  };

  return (
    <div className="relative border border-gray-300 rounded d-flex flex-row px-2 py-1">
      <input
        type="date"
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
        placeholder={placeholder}
        disabled={disabled}
        min={startDate || undefined}
        max={endDate || undefined}
        // style={{ paddingTop: '0.325rem', paddingBottom: '0.325rem' }}
        className={`
            w-full 
            border-0 focus:ring-0 focus:outline-none
            disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-not-allowed
            ${className}
          `}
      />
      {isClearable && value && (
        <div
          role="button"
          onClick={handleClear}
          className="absolute right-8 top-1/2 -translate-y-1/2 cursor-pointer"
        >
          <Clear className="h-4 w-4 text-gray-500" />
        </div>
      )}
    </div>
  );
};
