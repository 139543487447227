import { AutorenewRounded, CloudDownload, Print } from "@material-ui/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ExtInventory, ExtItem, MaterialRequest } from "../../../models/model";
import {
  fetchExtInventory,
  fetchItems,
  fetchJobsProtoSimple,
  fetchMaterialRequests,
  fetchMaterialRequestsUrl,
  intlFormat,
  makeDateString,
} from "../../../helpers";
import { AppContext } from "../../../App";
import { PpicJobs } from "../../../masterbigsystem";
import Select from "react-select";
import { Spinner } from "react-bootstrap";
import { DateInput } from "../../ZGlobalComponents/DateInput";
import MyButton from "../../ZGlobalComponents/MyButton";

const MRReportPage = () => {
  const ctx = useContext(AppContext);
  const [filters, setFilters] = useState({
    showHidden: false as boolean | null,
  });

  const [materialRequests, setMaterialRequests] = useState<MaterialRequest[]>(
    []
  );
  // const loadingData = useRef(false);
  const [loadingData, setLoadingData] = useState(false);

  const selectedStatus = useRef(null as number | null);
  const unCut = useRef(null as boolean | null);

  const [materialRequestsAll, setMaterialRequestsAll] = useState<
    MaterialRequest[]
  >([]);

  const printMode = useRef(true);

  const [loading, setLoading] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [items, setItems] = useState<ExtItem[]>([]);

  // const from = useRef(makeDateString(new Date()));
  // const to = useRef(makeDateString(new Date()));
  const [from, setFrom] = useState(makeDateString(new Date()));
  const [to, setTo] = useState(makeDateString(new Date()));

  const [jobs, setJobs] = useState<PpicJobs>(
    PpicJobs.fromPartial({ jobs: [] })
  );
  const [inventory, setInventory] = useState<ExtInventory[]>([]);

  const fetchExtInventoryData = async () => {
    try {
      //   setLoadingInventory(true);
      setInventory(await fetchExtInventory({ apiKey: ctx?.apiKey ?? "" }));
    } catch (e) {
    } finally {
      //   setLoadingInventory(false);
    }
  };

  const fetchItemsData = async () => {
    try {
      setLoadingItems(true);
      setItems(
        (await fetchItems(
          process.env.REACT_APP_BASE_URL ?? "",
          ctx?.apiKey ?? ""
        )) ?? []
      );
    } catch (e) {
    } finally {
      setLoadingItems(false);
    }
  };
  const fetchJobsIdNameData = async () => {
    try {
      //   setLoadingJobIdName(true);

      const d = await fetchJobsProtoSimple({
        apiKey: ctx?.apiKey ?? "",
        withProducts: true,
      });

      if (d) {
        setJobs(d);
      }
    } catch (e) {
    } finally {
      //   setLoadingJobIdName(false);
    }
  };

  const fetchMaterialRequestsData = async () => {
    try {
      setLoadingData(true);
      render();

      console.log("fromto", from, to);
      const materialRequestsData = await fetchMaterialRequests({
        apiKey: ctx?.apiKey ?? "",
        showHidden: filters.showHidden,
        from: from ? new Date(`${from}T00:00:00`).toISOString() : "",
        to: to ? new Date(`${to}T23:59:59`).toISOString() : "",
        unCut: unCut.current,
      });

      setMaterialRequests(materialRequestsData);
      setMaterialRequestsAll(materialRequestsData);

    } catch (e) {
      console.error(e);
    } finally {
      setLoadingData(false);
      render();
    }
  };
  const [, refresh] = useState(false);
  const render = () => {
    refresh((n) => !n);
  };

  const handleInit = async () => {
    fetchMaterialRequestsData();
    fetchItemsData();
    fetchJobsIdNameData();
    fetchExtInventoryData();
    // fetchExtInventoryData();
    // fetchExtReserveListData();
  };
  useEffect(() => {
    handleInit();
  }, []);

  // useEffect(() => {
  //   console.log(from, to);

  //   fetchMaterialRequestsData();
  //   // fetchAutoMRWarehouseFiltersData();
  // }, [from.current, to.current]);

  return (
    <>
      <div>
        <div>
          <div>
            <h4>MR Report Page</h4>
          </div>
          <div className="d-flex justify-content-between align-items-end">

            <div className="d-flex  align-items-end flex-grow-1">
              <div className="d-flex flex-column mr-1 px-0 ">
                <label className="small font-weight-bold  mb-0">From:</label>
                <DateInput
                  value={from}
                  endDate={to}
                  isClearable={true}
                  onChange={(e: string) => {
                    // if (e !== "") {
                    setFrom(e)
                    // }
                  }}
                />
              </div>
              {/* <div>
                  <div>
                    <small>
                      <strong>From</strong>
                    </small>
                  </div>
                  <div>
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      defaultValue={from.current}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          return;
                        }

                        from.current = e.target.value;
                        // fetchMaterialRequestsData();

                        render();
                      }}
                    />
                  </div>
                </div> */}
              <div className="d-flex flex-column mr-1 px-0 ">
                <label className="small font-weight-bold  mb-0">To:</label>
                <DateInput
                  value={to}
                  startDate={from}
                  isClearable={true}
                  onChange={(e: string) => {
                    // if (e !== "") {
                    setTo(e)
                    // render();
                    // }
                  }}
                />
              </div>
              {/* <div>
                  <div>
                    <small>
                      <strong>To</strong>
                    </small>
                  </div>
                  <div>
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      defaultValue={to.current}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          return;
                        }

                        to.current = e.target.value;
                        // fetchMaterialRequestsData();

                        render();
                      }}
                    />
                  </div>
                </div> */}

              <div className="col-12 col-md-3 col-xl-2 px-0  mr-1">
                <label className="small font-weight-bold  mb-0">Status:</label>
                {/* <div> */}
                {/* <div>
                      <small>
                        <strong>Status</strong>
                      </small>
                    </div> */}
                {/* <div style={{ width: 150 }}> */}

                <Select
                  options={[
                    { label: "Not Approved", value: 0 },
                    { label: "Approved", value: 1 },
                    { label: "Cutstock", value: 2 },
                    { label: "Outstanding", value: 3 },
                    { label: "All", value: null },
                  ]}
                  // getOptionLabel={(j) => `${j.name}`}
                  placeholder="Select status... "
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),

                  }}
                  menuPortalTarget={document.body}
                  value={[
                    { label: "Not Approved", value: 0 },
                    { label: "Approved", value: 1 },
                    { label: "Cutstock", value: 2 },
                    { label: "Outstanding", value: 3 },
                    { label: "All", value: null },
                  ].find((d) => d.value === selectedStatus.current)}
                  onChange={(v) => {
                    const val = v as { value: number | null };
                    selectedStatus.current = val.value;
                    if (val.value === 3) {
                      unCut.current = true;
                    } else {
                      unCut.current = false;
                    }
                    render();
                  }}
                />
                {/* </div> */}
                {/* </div> */}
              </div>

              <Spinner animation="border" hidden={!loadingData} />
              <MyButton
                variant="blue"
                onClick={() => {
                  if (!from) {
                    if (window.confirm("The 'from date' is empty. This may load a large amount of data. We recommend downloading the data as an XLS file instead. Do you still want to proceed with loading the data?")) {
                      fetchMaterialRequestsData();
                      render();
                    }
                  } else {
                    fetchMaterialRequestsData();
                    render();
                  }
                }}
                hidden={loadingData}
                disabled={loadingData}
                label="Get Data"
              />
            </div>

            <div className="d-flex">
              <div className="mx-2">
                <a
                  href={`${fetchMaterialRequestsUrl({
                    apiKey: ctx?.apiKey ?? "",
                    showHidden: filters.showHidden,
                    from: from ? new Date(`${from}T00:00:00`).toISOString() : "",
                    to: to ? new Date(`${to}T23:59:59`).toISOString() : "",
                    unCut: unCut.current,
                  })}&excel=true`}
                  target="_blank"
                >
                  <button className="btn btn-sm btn-success" onClick={() => { }}>
                    <CloudDownload /> XLS
                  </button>
                </a>
              </div>

              <div>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    //   printMode.current = true;
                    //   render();

                    window.print();

                    //   setTimeout(() => {
                    //     window.print();
                    //   }, 500);
                  }}
                >
                  <Print /> Print
                </button>
              </div>
            </div>
          </div>
          <hr className="border border-dark" />
          <div
            className={`${printMode.current ? `` : `overflow-auto`} `}
            style={{
              resize: "vertical",
              height: printMode.current ? `` : "75vh",
            }}
          >
            <table
              className="table table-sm"
              style={{ borderCollapse: "separate" }}
            >
              <tr>
                {[
                  "#",
                  "MR #",
                  "Job",
                  // "Remark",
                  "MFR",
                  "PN",
                  // "PartName",
                  "Desc",
                  "UM",
                  "Qty",
                  "Date",
                  "MatType",
                  "WH",
                  "Requested By",
                  // "Realtime Price",
                  // "Snapshot Price",
                  "Status",
                  // "Created",
                ].map((h) => {
                  return (
                    <>
                      <th
                        className="bg-dark text-light"
                        style={{
                          position: printMode.current ? undefined : "sticky",
                          top: printMode.current ? `` : 0,
                          maxWidth: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {h}
                      </th>
                    </>
                  );
                })}
              </tr>
              {materialRequests
                .filter((m) =>
                  selectedStatus.current !== null
                    ? selectedStatus.current === 3
                      ? m.status === 0 || m.status === 1
                      : m.status === selectedStatus.current
                    : true
                )
                .flatMap((m) =>
                  m.materialRequestItems?.map((i) => ({ item: i, mr: m }))
                )
                .map((d, i) => {
                  const foundItem = items.find(
                    (i) => `${i.id}` === `${d?.item.extItemId}`
                  );
                  const foundInventory = inventory.find(
                    (i) => `${i.id}` === `${d?.item.extInventoryId}`
                  );
                  return (
                    <>
                      <tr>
                        <td className="border border-dark p-0 m-0">{i + 1}</td>
                        <td className="border border-dark p-0 m-0">
                          MR/PPIC/
                          {(() => {
                            try {
                              return new Date(
                                d?.mr.createdAt ?? ""
                              ).getFullYear();
                            } catch (e) {
                              return 0;
                            }
                          })()}
                          /{d?.mr?.id}{" "}
                        </td>
                        <td className="border border-dark p-0 m-0">
                          {d?.mr.job?.name}
                        </td>

                        {/* <td className="border border-dark p-0 m-0">
                          {d?.mr.remark}
                        </td> */}
                        <td className="border border-dark p-0 m-0">
                          {foundItem?.mfr}
                        </td>
                        <td className="border border-dark p-0 m-0">
                          {foundItem?.partNum}
                        </td>
                        {/* <td className="border border-dark p-0 m-0">
                          {foundItem?.partName}
                        </td> */}
                        <td className="border border-dark p-0 m-0">
                          {foundItem?.partDesc}
                        </td>
                        <td className="border border-dark p-0 m-0">
                          {foundItem?.defaultUm}
                        </td>
                        <td className="border border-dark p-0 m-0">
                          {d?.item?.qty !== undefined ? (d.item.qty % 1 !== 0 ? d.item.qty.toFixed(2) : d.item.qty) : ''}
                        </td>
                        <td className="border border-dark p-0 m-0">
                          {d?.mr?.neededDate ? (
                            (() => {
                              try {
                                return Intl.DateTimeFormat(
                                  navigator.language ?? "en-US",
                                  { dateStyle: "medium" } as any
                                ).format(new Date(d.mr.neededDate));
                              } catch (e) {
                                console.log(e);
                                return <></>;
                              }
                            })()
                          ) : (
                            <></>
                          )}
                        </td>
                        <td className="border border-dark p-0 m-0">
                          {d?.item.bomItemType}
                        </td>

                        <td className="border border-dark p-0 m-0">
                          {foundInventory?.warehouse?.warehouse?.name}:
                          {foundInventory?.warehouse?.whName}
                        </td>
                        <td className="border border-dark p-0 m-0">
                          {
                            ctx?.extUsers.find(
                              (u) => `${u.id}` === `${d?.mr.extRequestedById}`
                            )?.name
                          }
                        </td>
                        {/* <td className="border border-dark p-0 m-0">
                          IDR{" "}
                          {Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 2,
                          }).format(foundInventory?.priceRp ?? 0)}
                        </td>
                        <td className="border border-dark p-0 m-0">
                          IDR{" "}
                          {Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 2,
                          }).format(d?.item.snapshotPriceIdr ?? 0)}
                        </td> */}
                        <td
                          className={`border border-dark p-0 m-0 text-light font-weight-bold ${d?.mr.status === 0
                            ? `bg-danger`
                            : d?.mr.status === 2
                              ? `bg-info`
                              : `bg-success`
                            }`}
                        >
                          {d?.mr.status === 0 ? `Not Approved` : ``}
                          {d?.mr.status === 1 ? `Approved` : ``}
                          {d?.mr.status === 2 ? `CutStock` : ``}
                        </td>
                        {/* <td className="border border-dark p-0 m-0">
                          {intlFormat({
                            date: d?.mr.createdAt,
                            dateStyle: "medium",
                            timeStyle: "short",
                          })}
                        </td> */}
                      </tr>
                    </>
                  );
                })}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default MRReportPage;
